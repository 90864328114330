/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
 

		// Fix IE scrolling behaviour
        if(navigator.userAgent.match(/Trident\/7\./)) { // if IE
          $('body').on("mousewheel", function () {
            // remove default behavior
            event.preventDefault(); 

            //scroll without smoothing
            var wheelDelta = event.wheelDelta;
            var currentScrollPosition = window.pageYOffset;
            window.scrollTo(0, currentScrollPosition - wheelDelta);
          });
        }       
          
        $( "#impressum" ).hide();




		// Klick auf den Button
        $( "#menu-trigger" ).click(function() {
	      if ($(".overlay_menu").hasClass("open")) {
		    $(".overlay_menu").removeClass("open").addClass("close").delay(500).queue(function(next){
			  $(this).removeClass("close");
              next();
			});
	      } else {
            $(".overlay_menu").removeClass("close").addClass("open");
          }
        });

		// Klick auf den Hintergrund
        $( ".overlay_menu" ).click(function() {
	      if ($(".overlay_menu").hasClass("open")) {
		    $(".overlay_menu").removeClass("open").addClass("close").delay(5000).queue(function(next){
			  $(this).removeClass("close");
              next();
			});
	      } else {
            $(".overlay_menu").removeClass("close").addClass("open");
          }
        });
                
        


//        $('a.lightbox').nivoLightbox();
//        $('div.lightbox').nivoLightbox();

        $('#parallax-barbarabertolini').backstretch([
          '/app/themes/bertolini/dist/images/barbara-bertolini-lg.jpg'
        ], {centeredY: false});
        
        $('#parallax-veranstaltungen').backstretch([
          '/app/themes/bertolini/dist/images/veranstaltungen-lg.jpg'
        ], {});       

        $('#parallax-europeanbankingcongress').backstretch([
          '/app/themes/bertolini/dist/images/bertolini-draghi-lg.jpg'
        ], {});
                
            
        // external links
        $('a[href$=".pdf"]').click(function(){open(this.href);return false;});
        $('a[href^="http"]:not(.fancybox):not(.video)').newWin();

        // imprint
        $( ".btn-impressum" ).click(function() {
          $( "#impressum" ).slideDown( "fast", function() {

          $('html, body').animate({
            scrollTop: $("#impressum").offset().top
          }, 1700, function () {
            window.location.hash = "impressum";
          });

          });
        });
                
        // soft scrolling
        var $root = $('html, body');
        $('a:not(.carousel-control):not(.badge)').click(function() {
          var href = $.attr(this, 'href');
          if ($(".overlay_menu").hasClass("open")) {
		    $(".overlay_menu").removeClass("open").addClass("close").delay(500).queue(function(next){
		      $(this).removeClass("close");
              next();
		    });
	      }
	        
          $root.animate({
            scrollTop: $(href).offset().top
          }, 1200, function () {
            window.location.hash = href;
	      });
        return false;
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        $( "#impressum" ).hide();
        $('.final-tiles-gallery').finalTilesGallery({
	      margin: 10,
	      gridSize: 13,
	      imageSizeFactor:	[[4000, 0.7],[1024, 0.6],[800, 0.5],[600, 0.4],[480, 0.3]]	
//		  layout: 'columns',
//		  gridSize: 0
		});
        $('.fancybox').fancybox({
          tpl: {
            error:'<p class="fancybox-error">Das Laden des Inhalts hat nicht geklappt.<br />Bitte später nochmals versuchen.</p>',
            closeBtn:'<div title="Schließen" class="fancybox-item fancybox-close"></div>',
            next:'<a title="Weiter" class="fancybox-nav fancybox-next"><span></span></a>',
            prev:'<a title="Zurück" class="fancybox-nav fancybox-prev"><span></span></a>'
          },
		  prevEffect	: 'none',
		  nextEffect	: 'none',
		  helpers	: {
			title	: {
			  type: 'outside'
			},
	        overlay : {
              speedIn : 420,
              opacity : 0.8,
              locked: false
            },
    	    thumbs	: {
			  width	: 50,
			  height	: 50
			}
		  },
		  padding: 0,
		  margin: 21
	    }); 
	    
        // fancyBox media handling
        $('.video').fancybox({
          tpl: {
            error:'<p class="fancybox-error">Das Laden des Inhalts hat nicht geklappt.<br />Bitte später nochmals versuchen.</p>',
            closeBtn:'<div title="Schließen" class="fancybox-item fancybox-close"></div>',
            next:'<a title="Weiter" class="fancybox-nav fancybox-next"><span></span></a>',
            prev:'<a title="Zurück" class="fancybox-nav fancybox-prev"><span></span></a>'
          },
          openEffect  : 'none',
	      closeEffect : 'none',
      	  helpers	: {
	      	media : {},
			title	: {
			  type: 'outside'
			},
		    overlay   : {
		      locked   : false
		    }
		  },
		  padding: 0,
		  margin: 21
  	    });

  	  // sprecher
      var $containerSprecher = $('#sprecher').isotope({
        // options...
        itemSelector: '.item',
        layoutMode: 'masonry',
        masonry: {
          columnWidth: '.col-md-4'
        }
      });
      $containerSprecher.imagesLoaded().progress( function() {
        $containerSprecher.isotope('layout');
       });
       
  	  // sprecher
      var $containerHotels = $('.hotels').isotope({
        // options...
        itemSelector: '.hotel',
        layoutMode: 'masonry',
        masonry: {
          columnWidth: '.hotel'
        }
      });
      $containerHotels.imagesLoaded().progress( function() {
        $containerHotels.isotope('layout');
       });       
       
       
      var $container = $('.isotope').isotope({
        // options...
        itemSelector: '.testimonial',
        layoutMode: 'masonry',
        masonry: {
          columnWidth: '.col-md-4'
        }
      });
      $container.imagesLoaded().progress( function() {
        $container.isotope('layout');
       });
	    
	    
	    
	function waitForWebfonts(fonts, callback) {
    var loadedFonts = 0;
    for(var i = 0, l = fonts.length; i < l; ++i) {
        (function(font) {
            var node = document.createElement('span');
            // Characters that vary significantly among different fonts
            node.innerHTML = 'giItT1WQy@!-/#';
            // Visible - so we can measure it - but not on the screen
            node.style.position      = 'absolute';
            node.style.left          = '-10000px';
            node.style.top           = '-10000px';
            // Large font size makes even subtle changes obvious
            node.style.fontSize      = '300px';
            // Reset any font properties
            node.style.fontFamily    = 'sans-serif';
            node.style.fontVariant   = 'normal';
            node.style.fontStyle     = 'normal';
            node.style.fontWeight    = 'normal';
            node.style.letterSpacing = '0';
            document.body.appendChild(node);

            // Remember width with no applied web font
            var width = node.offsetWidth;

            node.style.fontFamily = font;

            var interval;
            function checkFont() {
                // Compare current width with original width
                if(node && node.offsetWidth !== width) {
                    ++loadedFonts;
                    node.parentNode.removeChild(node);
                    node = null;
                }

                // If all fonts have been loaded
                if(loadedFonts >= fonts.length) {
                    if(interval) {
                        clearInterval(interval);
                    }
                    if(loadedFonts === fonts.length) {
                        callback();
                        return true;
                    }
                }
            }

            if(!checkFont()) {
                interval = setInterval(checkFont, 50);
            }
        })(fonts[i]);
      }
    }

        waitForWebfonts(['Brown-Regular', 'Brown-Bold'], function() {
          // Will be called as soon as ALL specified fonts are available
          $container.isotope('layout');
        });
	    
    
		$(document).ready(function($) {
          $container.isotope('layout');
        });
        

           
      }  
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        
        // Flexgalleries
        $('#flexgallery_netzwerk').freetile({
	      animate: true,
		  elementDelay: 70
        });
        $('#flexgallery_award').freetile({
	      animate: true,
		  elementDelay: 70
        });
        $('#flexgallery_navidi').freetile({
	      animate: true,
		  elementDelay: 70
        });                        
        $('#flexgallery_probono').freetile({
	      animate: true,
		  elementDelay: 70
        });
        
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS

		// Yeah! Super duper für die Babsi :)
        $('#flexgallery_netzwerk').freetile({
        });
        $('#flexgallery_award').freetile({
        });
        $('#flexgallery_navidi').freetile({
        });                        
        $('#flexgallery_probono').freetile({
        });

        // ScrollMagic
        // 
        // https://ihatetomatoes.net/wp-content/uploads/2015/05/GreenSock-CheatsheetV1-1.pdf
        var controller = new ScrollMagic.Controller();
   
	    var scene0 = new ScrollMagic.Scene({
          triggerElement: "#header-barbarabertolini",
          triggerHook: "onEnter",
          offset: 0,
          duration: "200%"
        })
//       .setTween("#parallax-barbarabertolini", 0.25, { y: "80%", ease:Linear.easeNone }) // trigger a TweenMax.to tween
        .setTween("#parallax-barbarabertolini", 0.25, { top: "40%", ease:Linear.easeNone })
        .addTo(controller);
 
        var scene1 = new ScrollMagic.Scene({
          triggerElement: "#header-veranstaltungen",
          triggerHook: "onEnter",
          offset: 0,
          duration: "200%"
        })
        .setTween("#parallax-veranstaltungen", 0.25, { top: "40%", ease:Linear.easeNone }) // trigger a TweenMax.to tween
        .addTo(controller);
                      
        var scene2 = new ScrollMagic.Scene({
          triggerElement: "#header-europeanbankingcongress",
          triggerHook: "onEnter",
          offset: 0,
          duration: "200%"
        })
        .setTween("#parallax-europeanbankingcongress", 0.25, { top: "40%", ease:Linear.easeNone }) // trigger a TweenMax.to tween
        .addTo(controller);
        
        var scene99 = new ScrollMagic.Scene({
          triggerElement: "#wappen",
          triggerHook: "onEnter",
          offset: 100,
          duration: "33%"
        })
//       .setTween("#parallax-barbarabertolini", 0.25, { y: "80%", ease:Linear.easeNone }) // trigger a TweenMax.to tween
        .setTween("a.maillink", 0.5, { opacity: 1, ease:Linear.easeNone })
        .addTo(controller);
        
        
      }
    },



    'scroll': {
      init: function() {
        // JavaScript to be fired on the home page
         // Yeah! Super duper für die Babsi :)
        $('#flexgallery_netzwerk').freetile({
	      animate: true,
		  elementDelay: 70
        });
        $('#flexgallery_award').freetile({
	      animate: true,
		  elementDelay: 70
        });
        $('#flexgallery_navidi').freetile({
	      animate: true,
		  elementDelay: 70
        });                        
        $('#flexgallery_probono').freetile({
	      animate: true,
		  elementDelay: 70
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
        
        
        
        // Yeah! Super duper für die Babsi :)
        $('#flexgallery_netzwerk').freetile({
        });
        $('#flexgallery_award').freetile({
        });
        $('#flexgallery_navidi').freetile({
        });                        
        $('#flexgallery_probono').freetile({
        });
        
        // ScrollMagic
        // 
        // https://ihatetomatoes.net/wp-content/uploads/2015/05/GreenSock-CheatsheetV1-1.pdf
        var controller = new ScrollMagic.Controller();
   
	    var scene0 = new ScrollMagic.Scene({
          triggerElement: "#header-barbarabertolini",
          triggerHook: "onEnter",
          offset: 0,
          duration: "200%"
        })
//       .setTween("#parallax-barbarabertolini", 0.25, { y: "80%", ease:Linear.easeNone }) // trigger a TweenMax.to tween
        .setTween("#parallax-barbarabertolini", 0.25, { top: "40%", ease:Linear.easeNone })
        .addTo(controller);
 
        var scene1 = new ScrollMagic.Scene({
          triggerElement: "#header-veranstaltungen",
          triggerHook: "onEnter",
          offset: 0,
          duration: "200%"
        })
        .setTween("#parallax-veranstaltungen", 0.25, { top: "40%", ease:Linear.easeNone }) // trigger a TweenMax.to tween
        .addTo(controller);
                      
        var scene2 = new ScrollMagic.Scene({
          triggerElement: "#header-europeanbankingcongress",
          triggerHook: "onEnter",
          offset: 0,
          duration: "200%"
        })
        .setTween("#parallax-europeanbankingcongress", 0.25, { top: "40%", ease:Linear.easeNone }) // trigger a TweenMax.to tween
        .addTo(controller);
        
        var scene99 = new ScrollMagic.Scene({
          triggerElement: "#wappen",
          triggerHook: "onEnter",
          offset: 100,
          duration: "33%"
        })
//       .setTween("#parallax-barbarabertolini", 0.25, { y: "80%", ease:Linear.easeNone }) // trigger a TweenMax.to tween
        .setTween("a.maillink", 0.5, { opacity: 1, ease:Linear.easeNone })
        .addTo(controller);
                    
      }
    },
    
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
